<template>
  <div class="container">
    <div class="base_info">
      <div class="user">
        <div class="avatar"><img src="http://img.centurysouth.cn/group1/default/20200925/10/17/0/123bdcdab8d75659d10c2d6acf100e8bdac7468c14d2b6-C8IcYy.jpg"></div>
        <div class="info">
          <div class="name">zhazhamao</div>
        </div>
      </div>
      <div class="tabs">
        <div class="tab" :class="[{'active': tabIndex === 1}]" @click="changeTab(1, '画板')">{{boardCount > 0 ? boardCount : ''}} 画板</div>
        <div class="tab" :class="[{'active': tabIndex === 2}]" @click="changeTab(2, '采集')">{{collectCount > 0 ? collectCount : ''}} 点赞</div>
        <!-- <div class="tab" :class="[{'active': tabIndex === 3}]" @click="changeTab(3, '喜欢')">{{likeCount > 0 ? likeCount : ''}} 喜欢</div>
        <div class="tab" :class="[{'active': tabIndex === 4}]" @click="changeTab(4, '标签')">{{tagCount > 0 ? tagCount : ''}} 标签</div> -->
        <div class="search">
          <input type="text" :placeholder="'搜索TA的' + searchType" v-model="search">
          <i class="el-icon-search flag"></i>
        </div>
      </div>
    </div>
    <div class="main_part">
      <div class="board_wapper" v-if="tabIndex === 1">
        <user-draw-board :span="4" :data="boardList"></user-draw-board>
      </div>
      <div v-if="tabIndex === 2">
        <my-waterfall :column="6" :data="collectList" @loadmore="loadmore">
          <div slot="buttonTR">
            <el-button type="success" size="small" @click.stop="">编辑</el-button>
          </div>
        </my-waterfall>
      </div>
    </div>
  </div>
</template>

<script>
import MyWaterfall from '@/components/MyWaterfall';
import UserDrawBoard from '@/components/UserDrawBoard';
export default {
  components: {
    MyWaterfall,
    UserDrawBoard,
  },
  data() {
    return {
      tabIndex: 1,
      search: '',
      searchType: '画板',
      boardList: [],
      boardCount: 0,
      collectList: [],
      collectPage: 1,
      collectCount: 0,
      lockBgColor: ['#8dfff7', '#ecc9c7', '#d1cfc0', '#abf9d0', '#c49ac7', '#93dd9b', '#c49ac7', '#b6e284', '#9adbf9', '#70d2db'],
      likeList: [],
      likePage: 1,
      likeCount: 0,
      tagList: [],
      tagPage: 1,
      tagCount: 0,
    }
  },
  methods: {
    changeTab(num, text) { // 切换tab栏
      this.tabIndex = num;
      this.searchType = text;
    },
    getCollectData() {  // 采集数据
      this.$service.DEFAULT({
        url: '/auth/ppt/fodder/list',
        method: 'GET',
        params: {
          cat_id: '',
          username: '',
          ord: '',
          title: '',
          use: '',
          page: this.collectPage,
        },
      }).then(res => {
        if (res.code == 200) {
          this.collectCount = res.data.count;
          this.collectList = this.collectList.concat(res.data.res);
        }
      })
    },
    loadmore() {  // 加载更多采集
      this.collectPage += 1;
      this.getCollectData();
    },
    getBoardData() {  // 画板数据
      this.$service.DEFAULT({
        url: '/auth/ppt/board/list',
        method: 'GET',
        params: {
          title: '',
          use: 0,
          username: '',
          cat_id: '',
        },
      }).then(res => {
        if (res.code == 200) {
          this.boardCount = res.data.count;
          this.boardList = this.boardList.concat(res.data.res);
        }
      })
    },
  },
  created() {
    this.getCollectData();
    this.getBoardData();
  },
}
</script>

<style lang="scss" scoped>
.container {
  box-sizing: border-box;
  width: 100%;
  min-width: 1200px;
  min-height: calc(100vh - 70px);
  padding: 70px 0 20px;
  .base_info {
    width: 90%;
    margin: 0 auto;
    background: #fff;
    box-shadow: 0 1px 3px 0 rgba(0,0,0,.02), 0 4px 8px 0 rgba(0,0,0,.02);
    .user {
      position: relative;
      height: 120px;
      .avatar {
        box-sizing: border-box;
        width: 150px;
        height: 150px;
        border-radius: 3px;
        border: 5px solid #fff;
        position: absolute;
        left: 20px;
        top: -50px;
        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
      .info {
        box-sizing: border-box;
        height: 100%;
        margin-left: 180px;
        padding: 5px;
        .name {
          font-size: 24px;
          font-weight: 700;
          color: #444;
        }
      }
    }
    .tabs {
      border-top: 1px solid #ededed;
      position: relative;
      margin-bottom: 10px;
      .tab {
        font-size: 14px;
        height: 48px;
        line-height: 48px;
        padding: 0 20px;
        color: #777;
        display: inline-block;
        cursor: pointer;
        &:hover {
          color: #9a0000;
        }
      }
      .active {
        background: #fff;
        color: #444;
        font-weight: 700;
      }
      .search {
        height: 32px;
        font-size: 14px;
        color: #bbb;
        background: #fafafa;
        border: 1px solid #ddd;
        border-radius: 2px;
        position: absolute;
        right: 10px;
        top: 50%;
        margin-top: -17px;
        input {
          width: 183px;
          height: 100%;
          background: #fff;
          border-color: #ececec;
          padding: 0 30px 0 10px;
          color: #999;
          border: none;
          outline: 0;
        }
        .flag {
          position: absolute;
          right: 6px;
          top: 50%;
          transform: translateY(-50%);
          font-size: 20px;
          cursor: pointer;
        }
      }
    }
  }
  .main_part {
    width: 90%;
    margin: 0 auto;
  }
}
</style>